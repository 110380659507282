import React from "react";
import { Link } from "react-router-dom";


const PillsItemComponent = (props) => {
    React.useEffect(() => {  
      }, []);
    return (     
        <Link 
        onClick={() => { props.setIdVideo(props.idVideo); }}  
        to="/movie-single"    
        >
            
         <div className="video-block"> 
         
                <div className="video-thumb position-relative thumb-overlay rounded">  
                                
                    <img alt="" className="img-fluid" src={props.imageUrl}/>
                        
                    <div className="video-content displayOnHover">
                    <h2 className="video-title m-0 d-flex py-0">
                        {props.title} <p className="mt-1">  &nbsp;{props.isSubtitle=='true' ? "   "+props.subtitle : ""}</p>
                        </h2>
                    <div className="video-info d-flex align-items-center m-0 py-0">
                        <span className="video-year">{props.year}</span> <span className="video-age">+18</span> <span className="video-type">{props.category}</span>
                    </div>
                    
                </div>
                
                </div> 
                
            </div>
            </Link>
            
    );
}

export default PillsItemComponent;