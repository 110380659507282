import React from "react";
import { useLocation, Link } from "react-router-dom";
import Util from "../util";

const PaymentPackComponent = (props) => {
    const location = useLocation();
    const json = location.state.json;
    const pack = location.state.pack;
    const [buyComplete, setBuyComplete] = React.useState('');

    const getStatusRent = () => {
      Util.get(Util.API+"status-rent/?idPack="+pack.id+"&email="+localStorage.getItem('email'))
        .then(async (response) => {
            if(response.data==1) {
              setBuyComplete('true');
              return;
            }
            if(response.data==0) {
              setBuyComplete('false');
              return;
            }
            if(response.data==-1) {
              setBuyComplete('');
            }
            await new Promise(r => setTimeout(r, 2000));
            getStatusRent();
            return;
          })
          .catch((error) => {
            console.log(error);
          });
    }

    React.useEffect(() => {      
      window.scrollTo(0, 0);
          async function check() {
            await new Promise(r => setTimeout(r, 2000));
            getStatusRent();
          }
          check();
          

      }, [location.key]);


    
  return (
    <div>
      
      <div className="play-thumb mb-4 text-center rounded">
            <br></br>
            <br></br>
            
            <h5 className="text-danger">{pack.name}</h5>
            <p className="">{pack.description}</p>
            <Link
            to="/movie-single"
            >
              <img className="image-payment" src={json.imageTitle}></img>
            <img
                        className="img-fluid img-thumb rounded"
                        src={ json.imageThumb}
                        alt=""
                    />
            </Link>
            <br></br>
            <br></br>
            <h5 className="text-concept"><span className="text-danger"><small>Comprar por </small></span>{pack.price/100}€</h5>
    </div>
      {buyComplete==='' ? <iframe id="iFrame" className="" 
      src={"https://islandfighters.com/redsys/getPetPack.php?idPack="+pack.id+"&email="+localStorage.getItem('email') }
      style={{border: 'none',  height: '200px', width: '100%'}}
          loading="lazy"
          >            
      </iframe>
      : buyComplete==='true' 
        ? <h5 className="text-center m-5">Compra completada</h5>
        : <h5 className="text-center m-5">No se ha podido completar la operación</h5>
      }
    </div>
    
  );
};

export default PaymentPackComponent;