import React from "react";
import { useLocation, Link } from "react-router-dom";
import Util from "../util";

const PaymentLiveComponent = (props) => {
    const location = useLocation();
    const [buyComplete, setBuyComplete] = React.useState('');
    //const [json, setJson] = React.useState(null);
    const json = props.idVideo;
    const getStatusRent = () => {
       let id=props.idVideo.id;

       //props.idVideo es el json del directo, las prisas y tal, cambiale el nombre
       console.log(props.idVideo);
      Util.get(Util.API+"status-rent/?idLive="+id+"&email="+localStorage.getItem('email'))
        .then(async (response) => {
            console.log("Response data "+response.data);
            
            if(response.data==1) {
              setBuyComplete('true');
              return;
            }
            if(response.data==0) {
              setBuyComplete('false');
              return;
            }
            if(response.data==-1) {
              setBuyComplete('');
            }
            await new Promise(r => setTimeout(r, 2000));
            getStatusRent();
            return;
          })
          .catch((error) => {
            console.log(error);
          });
    }
    const getLive = () => {
        // Retorna la promesa devuelta por Util.get
        return Util.get(Util.API + "list/?live=9")
          .then(async (response) => {
            if (response.data) {
             // setJson(response.data[0]);
              
              return response.data[0]; // Retorna los datos recibidos si es necesario
            }
          })
          .catch((error) => {
            console.log(error);
            // En este caso, puedes elegir retornar una promesa rechazada si lo deseas
            return Promise.reject(error);
          });
      };
      

    React.useEffect(() => {      
      window.scrollTo(0, 0);
          async function check() {
           // await getLive();
         //   await new Promise(r => setTimeout(r, 2000));
            getStatusRent();
          }
          check();
          

      }, [location.key]);


    
  return (
    json!==null ?
    <div>      
      <div className="play-thumb mb-4 text-center rounded">
            <br></br>
            <br></br>
            <h4>Directo</h4>
            <h5 className="text-danger">{json.name}</h5>
            <p className="">{Util.seconsToDate(json.dateStart*1000)}</p>
            <h5 className="text-concept"><span className="text-danger"><small>Comprar por </small></span>{json.price/100}€</h5>
            <Link
            to="/"
            >
               <img
                        className="img-fluid img-thumb rounded"
                        src={json.imageLive}
                        alt=""
                    />
            </Link>
            <br></br>
            <br></br>
    </div>
      {buyComplete==='' ? <iframe id="iFrame" className="" 
      src={"https://islandfighters.com/redsys/getPetLive.php?idLive="+json.id+"&email="+localStorage.getItem('email') }
      style={{border: 'none',  height: '200px', width: '100%'}}
          loading="lazy"
          >            
      </iframe>
      : buyComplete==='true' 
        ? <>
            <h5 className="text-center m-5">Compra completada</h5>
            <p  className="text-center m-5">Puedes verlo en la fecha y hora del directo en la sección <a className="text-danger" href="https://islandfighters.com/live">LIVE</a></p>
          </> 
        : <h5 className="text-center m-5">No se ha podido completar la operación</h5>
      }
    </div>
    : <></>
    
  );
};

export default PaymentLiveComponent;