import React from "react";
import Axios from "axios";
import Util from "../util";
import { Link } from "react-router-dom";

const ActiveSubscriptionComponent = (props) => {
    const [page, setPage] = React.useState('');
    const [textActive, setTextActive] = React.useState('');

    const email = React.createRef();
    const password = React.createRef();
    let isEnd = false;
    let idType = props.idType;
    React.useEffect(()=>{ 
        idType = props.idType;   
        setPage(LoginPage);
        if(props.idType === "1") setTextActive("ACTIVAR");      
        else setTextActive("CANCELAR");   
        }, []);
    
    
    const handleSubmit = (event) => {    
        event.preventDefault();
        var baseURL = Util.API+"login/";
        var bodyFormData = new FormData();
        bodyFormData.append('email', email.current.value);
        bodyFormData.append('password', password.current.value);
        Util.post(baseURL, bodyFormData)
        .then((response) => {
          if(response.data!=='') {
            //  localStorage.setItem('tokenUser', response.data);
            //  localStorage.setItem('email', email.current.value);
            ChangeActive(props.idType);
              
          }
          else {
            alert("Email o contraseña incorrecta");
          }
        }).catch((error) => {
           console.log(error);
        });
    }
    const ChangeActive = async (id) => {
        Util.get(Util.API+"subscriptions/active.php?status="+id+"&id="+localStorage.getItem("id"))
            .then((response) => {
            if(response.data=="OK")
            {
                setPage(EndLoginPage);
                isEnd=true;
            } 
           // console.log("RD "+response.data);
            })
            .catch((error) => {
            console.log(error);
            });
        }
    const LoginPage = () => {
        return (
            <div className="main-content">
            
            <section className="login">
                <div className="container">
                    <div className="row text-center intro">
                        <div className="col-12">
                            <p>Introduce tu email y contraseña para {props.idType==="0" ? "cancelar" : "activar"} la subscripción</p>
                            
                        </div>
                        
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12">
                            
                            <form id="login-form" onSubmit={handleSubmit} method="post" className="mb-4">
                                <div className="error-container"></div>
                                <div className="form-group">
                                    <label className="control-label col-xs-4">Email</label>
                                    <input id="email" ref={email} type="email" className="form-control" name="email" required="required"/>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-xs-4">Contraseña</label>
                                    <input ref={password} type="password" className="form-control" name="password" required="required"/>
                                </div>
                                <div className="form-group">
                                    {/*<label className="checkbox-inline mb-3"><input type="checkbox" /> Guardar contraseña</label>*/}
                                    <button type="submit" className="btn btn-block hvr-sweep-to-right btn-primary btn-lg">
                                    {props.idType==="0" ? "Cancelar subscripción" : "Activar subscripción"}
                                    </button>
                                </div>

                            </form>
                            
                            
                            </div>
                        
                        
                    </div>
                    
                </div>
                
            </section>
            
        </div>
        );
    }

    const EndLoginPage = () => {
        
        return (
            <div className="main-content">
            <div className="container">
                        <div className="col-12 text-center">
                        <img alt="" className="img-fluid" src="images/footer-logo.webp"/>
                             {props.idType==="0" 
                             ? <h2>Subscripción cancelada</h2>
                            :  <h2>Subscripción activada</h2>}
                            <a href="/" id="buttonLogin"><button className="btn btn-block hvr-sweep-to-right btn-primary btn-lg">Continuar</button></a>
                        </div>
                        </div>
                        
                    </div>
        );
    }
    return (
        page
    );
    

    
}
export default ActiveSubscriptionComponent;