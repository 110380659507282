import React from "react";
import { useLocation, Link } from "react-router-dom";
import  Axios  from "axios";
import Util from "../util";

const ResetPaswwordComponent = () => {
    const location = useLocation();
    const email = React.createRef();
    const [text, setText] = React.useState('');
    const handleSubmit = (event) => {    
        event.preventDefault();
        if(email.current.value=="")
        {
            setText("Introduce un email");
            return;
        }
        let button = event.target[1];
        var baseURL = Util.API+"reset-password/";
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        var bodyFormData = new FormData();
        bodyFormData.append('email', email.current.value);
        Axios({
          method: "post",
          url: baseURL,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
          
          
        })
        .then((response) => {
          if(response.data!=='') {
              setText('Se ha enviado un enlace de recuperación a la dirección proporcionada')
              button.disabled=true;
              event.target[1].disabled=true;
          }
          else {
            alert("El email proporcionado no es válido");
          }
        }).catch((error) => {
          console.log(error);
        });
    }
    const ResetPassPage = () => {
        return (
            <div className="main-content">
            
            <section className="reset-password">
                <div className="container">
                    <div className="row text-center intro">
                        <div className="col-12">
                        <img alt="" className="img-fluid" src="images/footer-logo.webp"/>
                            <h2></h2>
                            <p className="text-max-800">Restablece tu contraseña</p>
                            
                        </div>
                        
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12">
                            
                            {text!=='' 
                            ? <div>
                                <p className="text-center text-light">{text}</p> 
                                <Link to="/login">
                                <button type="submit" className="btn btn-block hvr-sweep-to-right btn-primary btn-lg">OK</button>
                                </Link>
                                </div>
                            : <form id="login-form" onSubmit={handleSubmit} method="post" className="mb-4">
                                <div className="error-container"></div>
                                <div className="form-group">
                                    <label className="control-label col-xs-4">Email</label>
                                    <input id="email" ref={email} type="email" className="form-control" name="email" required="required"/>
                                </div>
                                <div className="form-group">
                                    {/*<label className="checkbox-inline mb-3"><input type="checkbox" /> Guardar contraseña</label>*/}
                                    <button type="submit" className="btn btn-block hvr-sweep-to-right btn-primary btn-lg">Enviar</button>
                                </div>
                                <p className="text-center">Introduce tu email. Se le enviará un enlace para restablecer la contraseña</p>
                            </form>}
                            <p className="text-center">¿No tienes una cuenta? <Link to="/sign-up" className="text-danger">REGISTRATE</Link></p>
                      
                            
                            
                            
                        </div>
                                                
                    </div>
                    
                </div>
                
            </section>
            
        </div>
        );
    }

    return (
        <ResetPassPage />
    );
}

export default ResetPaswwordComponent;