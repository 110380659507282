import React, {useState} from "react";
import { PillsItemComponent } from "../pills-item";
import { Swiper, SwiperSlide } from "swiper/react";
import Axios from "axios";


// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/swiper-bundle.css";
import { FreeMode, Navigation, Thumbs } from "swiper";
import {SwiperButtonsComponent} from "../swiper/swiper-buttons";
import Util from "../util";
import { Link } from "react-router-dom";
const ThumbsLiveComponent = (props) => {
    const [renderData, setRenderData] = useState(null);
    const [ subscriptionValid, setSubscriptionValid ] = React.useState('');
    
    React.useEffect(() => {     
        CheckSubscription();
    }, []);
    const CheckSubscription = async () => {
        let id = Util.generateUniqueId();
        Util.get(Util.API+"subscriptions/?id="+localStorage.getItem("id"))
            .then((response) => {
            if(response.data.includes("- Finaliza "))
            {
                getListVideos();
                setSubscriptionValid('true');
            } else {
                setRenderData("");
                setSubscriptionValid('false');
            }
           // console.log("RD "+response.data);
            })
            .catch((error) => {
            console.log(error);
            });
        }
    const getListVideos = async () => {
     Util.get(props.url+localStorage.getItem("email"))
        .then((response) => {
          if(response.data=="") {
            setRenderData("");
            return;
          }
          const json = response.data; 
          /*         
          const preJson = () => json.length<1 ? "" : <>
                <SwiperSlide key={Math.floor(Math.random() *(Math.random()*33)* json.id)+Date.now()+json.id+json.name}>                     
                <Link 
                onClick={() => { props.setIdVideo(json); }}  
                to="/buy-live"  
                >                    
                <div className="video-block">                 
                        <div className="video-thumb position-relative thumb-overlay rounded">  
                                    <h4>{json.name}</h4> 
                                    <p>{Util.seconsToDate(json.dateStart*1000)}</p>    
                                    <p style={{ fontSize: 'smaller' }}>Horario Islas Canarias</p>
                            <img alt="" className="img-fluid" src={json.imageLive}/>                                
                            <div className="video-content">
                            <h2 className="video-title m-0 d-flex py-0">
                                Live
                            </h2>                            
                        </div>                        
                  </div>                         
              </div>
              </Link>                    
             </SwiperSlide>
             </>;*/
         var preJson = json.map((data)=> {
          return (
            <SwiperSlide key={Math.floor(Math.random() *(Math.random()*33)* data.id)+Date.now()+data.id+data.name}>                     
            <Link 
            onClick={() => { props.setIdVideo(data); }}  
            to="/buy-live"  
            >                    
            <div className="video-block">                 
                    <div className="video-thumb position-relative thumb-overlay rounded">  
                                <h4>{data.name}</h4> 
                                <p>{Util.seconsToDate(data.dateStart*1000)}</p>    
                                <p style={{ fontSize: 'smaller' }}>Horario Islas Canarias</p>
                        <img alt="" className="img-fluid" src={data.imageLive}/>                                
                        <div className="video-content">
                        <h2 className="video-title m-0 d-flex py-0">
                            Live
                        </h2>                            
                    </div>                        
              </div>                         
          </div>
          </Link>                    
         </SwiperSlide>
          )
      }        
      )
       
        setRenderData(preJson); 
        console.log("Lenght "+ preJson.length);
        
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return (
      renderData=="" ? <></> :
      <div>
      <h2 className="block-title">{props.title}</h2>
        <Swiper
        navigation={{
            nextEl: ".custom_button_next",
            prevEl: ".custom_button_prev"
          }}
        
        spaceBetween={20}
        slidesPerView={2}
        freeMode={false}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 6,
            },
          }}
        className="swiper-thumb"
      >
        
                                {renderData}
    <SwiperButtonsComponent />
    </Swiper>
    
    </div>
    );
}

export default ThumbsLiveComponent;