import React from "react";
import DropdownItem from "../navbar/nav-item-dropdown/dropdown-item/dropdown-item.component";

import NotificationsComponent from "../../notifications/notifications.component";
const RightMenuComponent = () => {
    const [ token, setToken ] = React.useState('');
    React.useEffect(()=>{
        const loggedInUser = localStorage.getItem("tokenUser");
        if(loggedInUser)setToken(AccountLogin);
        }, []);

    const AccountLogin = () => {
        return(
            <li className="nav-item">
                    <div className="nav-account ml-2">
                        <div className="dropdown">
                            <div aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" id="dropdown-account" role="button"><img alt="" className="img-fluid user-icon rounded-circle" src="images/avatar/user.jpg"/></div>
                            <ul className="dropdown-menu dropdown-menu-right fade-up">
                                <DropdownItem href="/account" text="Cuenta" />
                                <DropdownItem href="/logout" text="Cerrar sesión" />
                            </ul>
                        </div>
                    </div>
                </li>
        );
    }

    
    return (
        <div className="col-lg-2 text-right right-menu-wrap">
            <ul className="nav d-flex align-items-center list-inline m-0 float-right">
                {/*<li className="nav-item">
                    <div className="nav-search">
                        <a className="nav-link modal-popup" href="#search-popup"><i className="icofont-ui-search"></i></a>
                    </div>
    </li>
                
                <li className="nav-item">
                    <div className="nav-filter">
                        <a className="nav-link openbtn" onClick={() => openNav()}><i className="icofont-clip"></i></a>
                    </div>
                </li>*/}
                <NotificationsComponent/>
                {token}
            </ul>
        </div>
    );
}

function openNav() {
    document.getElementById("f-Sidebar").style.width = "100%";
}

export default RightMenuComponent;