import React from "react";
import { Link, useLocation } from "react-router-dom";
import Util from "../../util";

const BannerMovieComponent = (props) => {
    const [canPlay, setCanPlay] = React.useState('');
    const [inPack, setInPack] = React.useState('');
    const [preRent, setPreRent] = React.useState('');
    const [json, setJson] = React.useState(null);
    const location = useLocation()

      React.useEffect(() => {
       // console.log(Util.API+"rents/?email="+localStorage.getItem("email")+"&idVideo="+props.idVideo);
      

        Util.get(Util.API+"rents/?email="+localStorage.getItem("email")+"&idVideo="+props.idVideo)
        .then((response) => {
           // console.log(props.json);
            if(response.data.response==="OK") {
                if(parseInt(props.json.datePreRent)>0) {
                    let timeNow = response.data.timeNow*1000;
                    
                    if(timeNow>=props.json.datePreRent*1000) {
                        setCanPlay('true');
                        setJson(response.data);
                    } else {
                        setPreRent('true');
                        setCanPlay('true');
                        setJson(response.data);
                    }
                }
                else {
                    setCanPlay('true');
                    setJson(response.data);
                }                
            } else { 
                setCanPlay('false')
                setJson(response.data);
                if(parseInt(props.json.datePreRent)>parseInt(response.data.timeNow)) {
                    //console.log(parseInt(props.json.datePreRent)+" "+parseInt(response.data.timeNow));

                }
                
            }
            Util.get(Util.API+"packs/?idUser="+localStorage.getItem("id")+"&idVideo="+props.idVideo)
            .then((response) => {
                 if(response.data=="OK")
                 {
                     setCanPlay("true");
                     setInPack("true");
                 }
                 else setInPack("false");                
             }           
            )
            .catch((error) => {
                console.log(error);
            });
            if(props.json.ppv=='free') {
                setCanPlay('true');
            }            
          })
          .catch((error) => {
            console.log(error);
          });
         
        
      }, [location.key]);
    
    const ButtonPlay = (props) => {
        return (<>
            <img className="img-fluid img-title img-center" src={props.json.imageTitle}></img>
                <br></br>
                <h5>{props.json.subtitle}</h5>
                <br></br>
                <br></br>
                <br></br>
            <div className="position-relative mb-4"> 
            {preRent!=='true' 
            ? <Link
            className="d-flex align-items-left"
            onClick={() => props.setIdVideo(props.idVideo)}
            state={{json: props.json, idVideo: props.idVideo}}
            to="/watch-movie"
            >
                <p className="text-left">&nbsp;&nbsp;</p>
                <div className="play-icon">
                    <div className="circle pulse"></div>
                    <div className="circle">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                            <polygon points="40,30 65,50 40,70"></polygon>
                        </svg>
                    </div>
                </div>
            </Link>
            : ""}
            <p className="text-left">&nbsp;&nbsp;Ver con subscripción</p>
            </div>
            </>
        );
    }
    const ButtonBuy = (props) => {
        
        return (
            json && 
            <div className="mx-auto">
                <br></br>
                <br></br>
                <br></br>
                <img className="img-fluid img-title" src={props.json.imageTitle}></img>
                <br></br>
                <h5>{props.json.subtitle}</h5>
                <div>
                    <Link
                className="btn hvr-sweep-to-right rounded"
                
                to="/buy"
                state={{json: props.json, idVideo: props.idVideo}}
                >
                            <i className="fas fa-shopping-cart fa-lg align-center"></i>
                            &nbsp;
                        
                            {parseInt(props.json.datePreRent)>parseInt(json.timeNow) ? "Pre-Alquilar" : "Alquilar" } 
                </Link>
                <br></br>
                <p className="text-left">&nbsp;&nbsp;{props.json.rentTime} horas por {props.json.rentPrice/100}€</p>
                </div>
                
                {parseInt(props.json.datePreRent)>parseInt(json.timeNow)
                ? <>
                    <p className="m-0">El video estará disponible:</p>
                    <h5 className="text-danger">{Util.seconsToDate(props.json.datePreRent*1000)}</h5> 
                    <p>En Pre-Alquiler a partir de esa fecha dispondrá de 48 horas para ver el video</p>
                  </>
                : <>
                <p>Dispondrá de 48 horas para ver el video desde que complete el pago</p>
              </>
                }
            </div>
        );
    }
  
    
    return (
       canPlay==="" ? <div className="pre-content"></div> 
       : inPack && 
        <>
        <div className="banner-single banner-wrap banner-bg mobile-100 m-0" style={{backgroundImage: "url("+props.imageUrl+")"}}>
            <div className="container-fluid">
                <div className="banner-content">
                    <div className="transparent-block">
                        <div className="banner-caption mobile-100">                            
                               { 
                               canPlay=='true' 
                               ? <ButtonPlay idVideo={props.idVideo} setIdVideo={props.setIdVideo.bind(this)} token={props.token} json={props.json}/> 
                               : <ButtonBuy title={props.title} idVideo={props.idVideo} setIdVideo={props.setIdVideo.bind(this)} json={props.json}/>
                               }   
                               <div>

                                {canPlay=='true' && props.json.ppv!=='free' && inPack!=="true"
                                ? <p>
                                   {preRent!=='true' ?
                                    <>En <span className="text-danger">{Util.secondsToString(json.restTime)}</span>  finaliza el alquiler</>
                                : <>
                                <h4>Alquilado en <span className="text-danger">Pre-Alquiler</span></h4>
                                <p>Disponible durante 48 horas a partir del: <h5 className="text-danger" >{Util.seconsToDate(props.json.datePreRent*1000)}</h5> </p>                                
                                </>}
                                </p> 
                                : <></>
                                }                               
                                </div>                         
                        </div>                        
                    </div>                    
                </div>                
            </div>           
        </div>        
        </>
    );
}

export default BannerMovieComponent;