import React from "react";
import NavItemComponent from "./nav-item/nav-item.component";
import NavItemDropdown from "./nav-item-dropdown/nav-item-dropdown.component";
import { NavLink } from "react-router-dom";
import Util from "../../util";
const NavbarComponent = (props) => {
    const [ subscriptionValid, setSubscriptionValid ] = React.useState('');

    const CheckSubscription = async () => {
        let id = Util.generateUniqueId();
        Util.get(Util.API+"subscriptions/?id="+localStorage.getItem("id"))
            .then((response) => {
            if(response.data.includes("- Finaliza "))
            {
                setSubscriptionValid('true');
            } else {
                setSubscriptionValid('false');
            }
           // console.log("RD "+response.data);
            })
            .catch((error) => {
            console.log(error);
            });
        }
    
        React.useEffect(() => {
            async function check () {   
                await CheckSubscription();         
            }
            check();
                   
            
          }, []);
        

    return (
        <div className="col-lg-6 pl-0">
            <nav className="navbar navbar-expand-lg">
                <div
                className="navbar-brand"
                >
                    <img alt="" className="img-fluid max-width-250" src="images/footer-logo.webp" style={{maxWidth: "250px"}}/>
                    
                </div>
                    

                <div className="site-nav-inner float-left">
                    <button style={{maxWidth: "50%"}} id="buttonCollapse" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation" className="navbar-toggler" data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
                        <img alt=""   style={{maxWidth: "120px", top: "0px"}} className="" src="images/footer-logo.webp"/> 
                        </button>  
                    
                    <div className="navbar-collapse navbar-responsive-collapse collapse" id="navbarSupportedContent">
                        <ul className="nav navbar-nav">

                            <NavItemComponent text='Inicio' href="/"/>
                            <NavItemComponent text='Live' href="https://islandfighters.com/live"/>
                            {subscriptionValid==='true' ? "" : <NavItemComponent text='Subscripcion' href="/#/buy-subscription"/>}
                            
                            {/*<NavItemComponent text='Novedades' href="/about"/>

                            <NavItemDropdown text="Access"/>*/}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default NavbarComponent;