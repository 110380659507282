import React from "react";
import { MainSliderItemComponent } from "./main-slider-item";
import { FooterComponent } from "../footer";

const MainSliderComponent = (props) => {
    return (
        <>
        <div className="main-slider" id="main-slider">
            <div className="slider big-slider slider-wrap">
                <MainSliderItemComponent  count="1" title="Iron Door" year="2021" duration="2h 6m"
                description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                imageUrl="images/slider/slider1.jpg"
                setIdVideo={props.setIdVideo.bind(this)}
                idVideo='01506d9f-7a20-44c3-86ae-92147de945d8'/>
                <MainSliderItemComponent  count="2" title="Iron Door" year="2021" duration="2h 6m"
                description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                imageUrl="images/slider/slider2.jpg"
                setIdVideo={props.setIdVideo.bind(this)}
                idVideo='01506d9f-7a20-44c3-86ae-92147de945d8'/>
                <MainSliderItemComponent  count="3" title="Iron Door" year="2021" duration="2h 6m"
                description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                imageUrl="images/slider/slider3.jpg"
                setIdVideo={props.setIdVideo.bind(this)}
                idVideo='01506d9f-7a20-44c3-86ae-92147de945d8'/>
            </div>
        </div>
        </>

    );
}

export default MainSliderComponent;