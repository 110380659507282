import React from "react";
import { NavLink } from "react-router-dom";

const NavItemComponent = (props) => {
  //let state = this.refs.collapsing_button.getAttribute('aria-hidden');
    return (
        <li className="nav-item" onClick={event => checkCollapse(event)}>
            <a
              className="nav-link "
              href={props.href}
            >
              { props.text }
            </a>
        </li>
    );
}

function checkCollapse(event) {
  //document.getElementById("f-Sidebar").style.width = "100%";
  let isHidden = document.getElementById("buttonCollapse").getAttribute('aria-expanded');
  if(isHidden) {
    document.getElementById("navbarSupportedContent").setAttribute('class','navbar-collapse navbar-responsive-collapse collapse');
  }
}
export default NavItemComponent;