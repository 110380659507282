import React, {useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperItemComponent from "./swiper-item/swiper-item.component";
import { SwiperButtonsComponent } from "./swiper-buttons";
import Util from "../util";

const SwiperComponent = (props) => {
    const controlledSwiper=null;
    const [jsonData, setJsonData] = useState(null);
    React.useEffect(() => {
      getListVideos();
    }, []);
  
    const getListVideos = async () => {
      Util.get(Util.API+"list"+(Util.isProduction ? "" : "debug"))
        .then((response) => {
          const json = response.data;
          var preJson = null;
          if(response.data!=='')
          preJson = json.map((data)=> {
            //console.log(data);
            return (
                <SwiperSlide 
                key={Math.floor(Date.now().toString(36) + Math.random().toString(36).substring(2))+data.id+data.title}>
                <SwiperItemComponent title={data.title} imageUrl={data.imageBackground} imageTitle={data.imageTitle}
                    description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." 
                    setIdVideo={props.setIdVideo.bind(this)}
                    idVideo={data.id}
                    ppv={data.ppv}
                    />
            </SwiperSlide>
            )
        }        
        );
        if(response.data!=='')
          setJsonData(preJson);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return (
      jsonData &&
      <Swiper modules={[Autoplay, Navigation, Pagination]} 
      style={{
        "--swiper-navigation-color": "#FF0000",
        "--swiper-pagination-color": "#FF0000",
      }}
        grabCursor={true}
        autoplay={{
            delay: 7000,
            disableOnInteraction: false,
          }}
        loop={true}
        centeredSlides={true}
        slidesPerView={"1"}
        
        parallax={true}
        
        pagination={true}
        navigation={{
          nextEl: ".custom_button_next",
          prevEl: ".custom_button_prev"
      }}
        className="mySwiper"
      >      
       {jsonData}              
       {props.buttons ? <>
       <div className="swiper-button-prev swiper-button-white" role="button"></div>
        <div className="swiper-button-next swiper-button-white"></div>
        <SwiperButtonsComponent percent100="percent100"/>
        </> : "" }       
      </Swiper>
      
      );
}

export default SwiperComponent;