import React from "react";
const EndFooterComponent = () => {
    return (
        <>
        <div className="zoom-anim-dialog mfp-hide modal-searchPanel search-form" id="search-popup">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="search-panel">
                        <form className="search-group">
                            <div className="input-group">
                                <input className="form-control" name="s" placeholder="Search" type="search" /> <button className="input-group-btn search-button"><i className="fas fa-search"></i></button>
                                </div>
                        </form>
                    </div>
                </div>
            </div>
    </div>
    <div className="filter-sidebar" id="f-Sidebar">
            <a className="closebtn"  href="#" onClick={() => closeNav()}><i className="fas fa-times"></i></a>
            <div className="filter-accordion" id="accordion">
                <div className="card">
                    <div className="card-header" id="headingOne">
                        <h2 className="mb-0"><button aria-controls="type" aria-expanded="false" className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-target="#type" data-toggle="collapse">Display Type <span className="icofont-circled-down"></span></button></h2>
                    </div>
                    <div aria-labelledby="headingOne" className="collapse" data-parent="#accordion" id="type">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">All</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Drama</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Action</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Romantic</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Crime</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Excitement</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Comedy</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Ambiguity</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Adventures</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Fantasia</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Horror</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Fiction</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="headingTwo">
                        <h2 className="mb-0"><button aria-controls="production" aria-expanded="false" className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-target="#production" data-toggle="collapse">Year of Production <span className="icofont-circled-down"></span></button></h2>
                    </div>
                    <div aria-labelledby="headingTwo" className="collapse" data-parent="#accordion" id="production">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">All</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2000</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2001</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2002</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2003</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2004</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2005</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2006</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2007</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2008</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2009</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2010</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2011</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2012</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2013</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2014</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2015</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2016</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2017</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2018</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2019</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2020</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2021</a>
                                </div>
                                <div className="col-4 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">2022</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="headingThree">
                        <h2 className="mb-0"><button aria-controls="quality" aria-expanded="false" className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-target="#quality" data-toggle="collapse">Display Quality <span className="icofont-circled-down"></span></button></h2>
                    </div>
                    <div aria-labelledby="headingThree" className="collapse" data-parent="#accordion" id="quality">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">All</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">720p HDTV</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">1080p BluRay</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">720p BluRay</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">1080p WEB-DL</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">720p WEBRip</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">720p HD</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">1080p HD</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">HDTV</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">720p WEB-Dl</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="headingFour">
                        <h2 className="mb-0"><button aria-controls="country" aria-expanded="false" className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-target="#country" data-toggle="collapse">Country of Production <span className="icofont-circled-down"></span></button></h2>
                    </div>
                    <div aria-labelledby="headingThree" className="collapse" data-parent="#accordion" id="country">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">All</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">France</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Canada</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">China</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Argentina</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Australia</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">United States</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Germany</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Japan</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Italy</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="headingFive">
                        <h2 className="mb-0"><button aria-controls="language" aria-expanded="false" className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-target="#language" data-toggle="collapse">The Language <span className="icofont-circled-down"></span></button></h2>
                    </div>
                    <div aria-labelledby="headingThree" className="collapse" data-parent="#accordion" id="language">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">All</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Arabic</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">English</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">German</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Spanish</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">French</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Italian</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Russian</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Japanese</a>
                                </div>
                                <div className="col-6 col-xl mb-xl-0">
                                    <a className="btn d-block" href="#">Chinese</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>   
        </>
        
    );
}

function closeNav() {
    document.getElementById("f-Sidebar").style.width = "0";
}
export default EndFooterComponent;