import React from "react";
import axios from "axios";
class Util extends React.Component {
  static Version = "1.0";
  static API = "https://islandfighters.com/api-v1/";
  static isProduction = true;

  static async get(url) {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; 
    return axios.get(url,{
      headers: 'DNT,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Range'
    })
  }
  static async post(url, formData) {
    var baseURL = url;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    var bodyFormData = formData;
    return axios({
      method: "post",
      url: baseURL,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },        
    })
  }
  static generateUniqueId() {
    let uniqueId = localStorage.getItem('deviceId');        
    if (!uniqueId) {
        uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
        localStorage.setItem('deviceId', uniqueId);
    }        
    return uniqueId;
  }
  static noImplementedYet() {
    alert("Funcionalidad no implementada. Estamos trabajando en ello.")
  }
  static secondsToString(seconds) {
    var hour = Math.floor(seconds / 3600);
    hour = (hour < 10)? '0' + hour : hour;
    var minute = Math.floor((seconds / 60) % 60);
    minute = (minute < 10)? '0' + minute : minute;
    var second = seconds % 60;
    second = (second < 10)? '0' + second : second;
    return hour + ' h ' + minute+' m';
  }
  static seconsToDate(seconds) {
    let sec = seconds;
    let normalDate = new Date(sec).toLocaleString('es-ES',{timeZone:'Europe/London'});
    return normalDate;
  }
  static seconsToDateOnlyDate(seconds) {
    let sec = seconds;
    let normalDate = new Date(sec).toLocaleString('es-ES',{timeZone:'Europe/London'}).split(',')[0];
    return normalDate;
  }
}

export default Util;
