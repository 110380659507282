import React from "react";
import { NavLink } from "react-router-dom";
import Util from "../util";

const NotificationsComponent = () => {
    const [notifications, setNotifications] = React.useState('');
    const [countNotifications, setCountNotifications] = React.useState(0);

    React.useEffect(()=>{
        getNotify();
        }, []);

    const delNotify = (id) => {
        Util.get(Util.API+"notifications/del.php?i="+id)
        .then((response) => {
            if(response.data==1) {
                console.log("ok");
            }

        })
        .catch((error) => {
          console.log(error);
        });
    }
    const getNotify = async () => {
        Util.get(Util.API+"notifications/?id="+localStorage.getItem('id'))
          .then((response) => {
            const json = response.data;
            setCountNotifications(json.length);
            if(json.length<=0) return;
            var preJson = json.map((data)=> {
              return (
                <NavLink 
                onClick={() => delNotify(data.id) }
                key={Date.now().toString()+data.idVideo+data.id}
                className="dropdown-item" to={"/movie-single/"+data.idVideo}>
                <div className="notification-card media">
                                                           
                    <div className="notification-content media-body">
                    <div className="notification-thumb"><img alt="" className="img-fluid"
                     src={data.image}/>
                     </div> 
                        <h2 className="notification-title">
                            {data.title}
                            </h2>
                            <span className="date"><i className="far fa-clock"></i> 
                         &nbsp;Añadida {Util.seconsToDateOnlyDate(data.dateStart*1000)}
                        </span>
                    </div>
                </div>
            </NavLink>
              )
             }                       
          );

          setNotifications(preJson);  
          })
          .catch((error) => {
            console.log(error);
          });
      }

    return(
        <li className="nav-item dropdown">                    
                <div className="nav-notification">  
                <a
                className="nav-link menu-dropdown" data-toggle="dropdown" href="#">
                <i className="icofont-notification"></i>
                 {countNotifications>0 ? <p className="badge">{countNotifications}</p>  : ""}
                 </a>                           
                    
                    <ul className="dropdown-menu dropdown-menu-right fade-up" role="menu" >
                        <li>
                            {notifications}
                        </li>
                    </ul>
                </div>
            </li>
    );
}

export default NotificationsComponent;