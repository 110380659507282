import React from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import Util from "../util";

class SignUpComponent extends React.Component {
    constructor(props) {      
      super(props);
      this.state = {value: this.SignUp, errorValue: ''};
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.username = React.createRef();
      this.password = React.createRef();
      this.checkpassword = React.createRef();
      this.email = React.createRef();
    }
    handleChange(event) {
      this.setState({value: event.target.value});
    }
  
    handleSubmit = (event) => {
      event.preventDefault();
      if(this.password.current.value!==this.checkpassword.current.value)
      {
        this.setState({errorValue: "Las contraseñas no coinciden"});
        return;
      }
      //console.log(this.username.current);
      var baseURL = Util.API+"sign-up/";
      Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      var bodyFormData = new FormData();
      bodyFormData.append('username', this.username.current.value);
      bodyFormData.append('email', this.email.current.value);
      bodyFormData.append('password', this.password.current.value);
      Util.post(baseURL, bodyFormData)
      .then((response) => {
        if(response.data!=='') {
            this.setState({value: this.OK});
            localStorage.setItem('tokenUser', response.data);
            localStorage.setItem('email', this.email.current.value);
        }
        else {
            this.setState({errorValue: "Ha ocurrido un error. Puede que el email ya esté registrado"});
        }
        
      }).catch((error) => {
        console.log(error);
      });


    }

    OK = () => {
        return( 
            <div className="row text-center intro">
                <div className="col-12">
                    <h2>Cuenta creada con éxito</h2>
                    <a href="/">
                    <button className="btn btn-block hvr-sweep-to-right btn-primary btn-lg" type="submit">Acceder</button>
                    </a>
                </div>
                
            </div>   
        );
    }

    SignUp = () => {
        return(
            <>
            <div className="row text-center intro">
                <div className="col-12">
                <img alt="" className="img-fluid" src="images/footer-logo.webp"/>
                <h2></h2>
                    <p className="text-max-800">Crea una cuenta</p>                  
                    <p className="text-max-800 text-danger">{this.state.errorValue}</p>
                </div>
                
            </div>                    
            <div className="row">
                <div className="col-lg-12">
                    
                    <form id="signup-form" onSubmit={this.handleSubmit} method="post">
                        <div className="error-container"></div>
                        <div className="row">  
                        <div className="col-md-6">
                                <div className="form-group">
                                    <label>Nombre</label>
                                    <input ref={this.username} className="form-control form-control-name" name="username" id="first-name" placeholder="" type="text" required="required"/>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input ref={this.email} type="email" className="form-control" name="email" required="required"/>
                                </div>
                            </div>
                            
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Contraseña</label>
                                    <input ref={this.password} type="password" className="form-control" name="password" id="password" required="required"/>
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Confirma contraseña</label>
                                    <input ref={this.checkpassword} type="password" className="form-control" name="confirm_password" id="confirm-password" required="required"/>
                                </div>
                            </div>
                            
                        </div>
                        <div className="form-group">
                            <label className="checkbox-inline mb-3" style={{width:"100%"}}><input type="checkbox" required="required"/> Acepto los <a href="https://islandfighters.com/terms" target="_blank">Términos de uso</a> y la <a href="https://islandfighters.com/privacy" target="_blank">Política de privacidad</a>.</label>
                            <button className="btn btn-block hvr-sweep-to-right btn-primary btn-lg" type="submit">Registrarse</button>
                        </div>
                    </form>
                    
                    <div className="text-center"><p>¿Ya tienes una cuenta? <Link className="text-danger" to="/login">ACCEDE AQUÍ</Link></p></div>
                </div>
                
            </div>                    
            </>   
        );
    }
    render() {
      return (
        <div className="main-content">
            <section className="signup">
                <div className="container">
                    <this.state.value />
                </div>                
            </section>
            {/*this.state.value == this.OK ? <FacebookPixelComponent /> : ""*/}
        </div>
      );
    }
}

export default SignUpComponent;