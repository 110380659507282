import React from "react";
import Axios from "axios";
import Util from "../util";
const TooMuchDevicesComponent = () => {
    const [emailSended, setEmailSended] = React.useState('');
    React.useEffect(()=>{
        localStorage.removeItem("tokenUser");   
        let countTrys = localStorage.getItem("countTrys");
        if(countTrys) {
            if(countTrys>3) {
                localStorage.setItem("countTrys", "0");
                Send(localStorage.getItem("email"));
                //console.log("envia email");
            }
            else {
                localStorage.setItem("countTrys", parseInt(countTrys)+1);
            }
        }
        else {
            localStorage.setItem("countTrys", "0");
        }
        //console.log(countTrys);
    }, []);
    
    const Send = async (email) => {
        Util.get(Util.API+"mail/reset.php?email="+email)
        .then((response) => {
         // console.log("Email "+response.data);
         setEmailSended("Revise su email. Se le ha enviado un mensaje a su cuenta");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  
    
    return(
        <div className="main-content">
        <div className="container">
            <div className="row col-12 text-center intro">
        <div className="col-12 center text-center">
        <img alt="" className="img-fluid" src="images/footer-logo.webp"/>
        <br></br>
            <h4>Demasiados dispositivos conectados</h4>
            <p>
                Esto ocurre cuando se ha alcanzado el máximo de dispositivos con sesión abierta posibles
            </p>
            <p>
                Cierre la sesión de otro dispositivo para abrirla en este
            </p>
            <p className="text-warning">{emailSended}</p>
            <a href="/" id="buttonLogin"><button className="btn btn-block hvr-sweep-to-right btn-primary btn-lg" >Continuar</button></a>
        </div>
        </div>
        </div>
    </div>
    );
}
export default TooMuchDevicesComponent;