import React from "react";
import { ThumbsSimpleComponent } from "../thumbs-simple";
import { SwiperComponent } from "../swiper";
import Util from "../util";
import ThumbsLiveComponent from "../thumb-live/thumb.live.component";

const MainContentComponent = (props) => {
    React.useEffect(()=>{
        window.scrollTo(0, 0);        
        }, []);
    return (
        <>
        <div className="main-content pre-content"> 
        {/*<img className="img-center" src="images/movies/the-battle-reality/the-battle-portada.webp"/>*/}
        
        <SwiperComponent  
        token = {props.token} setIdVideo={props.setIdVideo.bind(this)} buttons/>
            <br></br>
            <br></br>
            <ThumbsLiveComponent 
            token = {props.token} title="Directo" setIdVideo={props.setIdVideo.bind(this)}
            url= {Util.API+"list"+(Util.isProduction ? "" : "debug")+"/?live="}
            />
            <ThumbsSimpleComponent 
            token = {props.token} title="Sugerencias" setIdVideo={props.setIdVideo.bind(this)}
            url= {Util.API+"list"+(Util.isProduction ? "" : "debug")+"/?email="}
            />
            <ThumbsSimpleComponent 
            token = {props.token} isSubtitle='true' title="Disponible" setIdVideo={props.setIdVideo.bind(this)}
            url= {Util.API+"list"+(Util.isProduction ? "" : "debug")+"/can/?email="}
            />
            </div>
            
        </>
    );
}

export default MainContentComponent;