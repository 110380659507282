import React, {useState} from "react";
import { PillsItemComponent } from "../pills-item";
import { Swiper, SwiperSlide } from "swiper/react";
import Axios from "axios";


// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/swiper-bundle.css";
import { FreeMode, Navigation, Thumbs } from "swiper";
import {SwiperButtonsComponent} from "../swiper/swiper-buttons";
import Util from "../util";

const ThumbsSimpleComponent = (props) => {
    const [renderData, setRenderData] = useState(null);
    
    
    React.useEffect(() => {     
      getListVideos();
    }, []);
  
    const getListVideos = async () => {
     Util.get(props.url+localStorage.getItem("email"))
        .then((response) => {
          const json = response.data;
          
          var preJson = json.map((data)=> {
            return (
                <SwiperSlide key={Math.floor(Math.random() *(Math.random()*33)* data.id)+Date.now()+data.id+data.title}>
                <PillsItemComponent 
                    token={props.token}
                    setIdVideo={props.setIdVideo.bind(this)}
                    idVideo={data.id}
                    imageUrl={data.image}
                    title={data.title}
                    subtitle={data.subtitle}
                    duration={data.duration}
                    category={data.category}
                    year={data.year}
                    className="video-swiper"
                    isSubtitle={props.isSubtitle}
                />
                </SwiperSlide>
            )
        }        
        )
        
        setRenderData(preJson);
        
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return (
      renderData && renderData.length<1 ? <></> :
      <div>
      <h2 className="block-title">{props.title}</h2>
        <Swiper
        navigation={{
            nextEl: ".custom_button_next",
            prevEl: ".custom_button_prev"
          }}
        
        spaceBetween={20}
        slidesPerView={2}
        freeMode={false}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 6,
            },
          }}
        className="swiper-thumb"
      >
        
                                {renderData}
    <SwiperButtonsComponent />
    </Swiper>
    
    </div>
    );
}

export default ThumbsSimpleComponent;