import React, {useState} from "react";
import { ThumbsSimpleComponent } from "../thumbs-simple";
import data2 from "../../data/data2.json";
import { VideoDetailsComponent } from "../watch-movie/video-details";
import {BannerMovieComponent} from "./banner-movie";
import { useNavigate, useLocation } from "react-router-dom";
import { LatestEpisodiesComponent } from "../latest-episodies";
import Axios from "axios";
import Util from "../util";

const MovieSingleComponent = (props) => {
    const [bannerMovieRender, setBannerMovieRender] = useState(null);
    const [jsonData, setJsonData] = useState(null);
    const navigate = useNavigate();
    const refreshPage = () => {
        navigate(0);
    }
    const location = useLocation()

    const getListVideos = async (idVideo) => {
      setBannerMovieRender(null);
        if(idVideo==null)idVideo=props.idVideo;
        Util.get(Util.API+"list/?element="+idVideo)
          .then((response) => {
            if(!response.data)navigate('/');
            setJsonData(response.data);
            const json = response.data;
            if(json.isReady===0)navigate('/');
            var preJson = ()=> {
              return (
                <>                
                  <BannerMovieComponent 
                  json={json}
                  setIdVideo={props.setIdVideo.bind(this)} 
                  token={props.token}
                  idVideo={idVideo}
                  key={Math.floor(Date.now().toString(36) + Math.random().toString(36).substring(2))+json.id}
                  imageUrl={ json.imageThumb }
                  title={json.title}
                  year="2023"
                  />
                  <VideoDetailsComponent setIdVideo={props.setIdVideo.bind(this)}  json={json}/>
                  <br></br>
                  <br></br>
                  {json.isSerie=="1" 
                  ? <LatestEpisodiesComponent 
                  setIdVideo = {props.setIdVideo.bind(this)}
                  url= {Util.API+"list/serie/?idSerie="+json.idSerie+"&season=1&email"}
                  /> 
                  : <></>}
                  <br></br>
                  <br></br>
                  
                  <ThumbsSimpleComponent 
                  title="Similares" 
                  json={data2} setIdVideo={props.setIdVideo.bind(this)}
                  url= {Util.API+"list/?email="}
                  />
                  
                </>
              )
          }   
          setBannerMovieRender(preJson);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    React.useEffect(() => {
        //refreshPage();
        var idVideo = window.location.href.split("movie-single/")[1];
        if(idVideo) {
          getListVideos(idVideo);
        }
        else if(props.idVideo.length<2) {
            navigate('/');
        }
        else getListVideos(null);
       
        window.scrollTo(0, 0);
        
        

      }, [location.key]);
      
    return(
        bannerMovieRender ?        
        <div className="main-content"> 
        
            {bannerMovieRender}           
            
        </div> 
        : <div className="pre-content text-center"> <div className="lds-ripple"><div></div><div></div></div> </div>
    );
}

export default MovieSingleComponent;