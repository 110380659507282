import React from "react";
import {EndFooterComponent} from "./end-footer";
import { Link } from "react-router-dom";

const FooterComponent = () => {
    return (
    <>
    {/*<div className="main-footer">
            <div className="container-fluid mx-auto">
                <div className="row justify-content-lg-between justify-content-center">
                    <div className="col-md-12">
                        <div className="footer-widget">
                            <div className="widget-content">
                                <div className="footer-logo">
                                    <img alt=""  style={{maxWidth: "250px"}} className="img-fluid max-width-250" src="images/footer-logo.webp" />
                                    </div>
                                <div className="footer-about-text">
                                    <p className="text-muted">Here , write the complete address of the Registered office address along with telephone number.</p>
                                </div>
                                <div className="footer-social-icons">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <a href="#" title="twitter"><i className="fab fa-2x fa-twitter"></i></a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="#" title="facebook"><i className="fab fa-2x fa-facebook-f"></i></a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="#" title="instagram"><i className="fab fa-2x fa-instagram"></i></a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="#" title="youtube"><i className="fab fa-2x fa-youtube"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>*/}
    <div className="copyright">
                <div className="container-fluid">
                    <div className="row text-center">
                        <div className="col-md-12">
                        <img alt=""  style={{maxWidth: "250px"}} className="img-fluid max-width-250" src="images/footer-logo.webp" />
                                    
                        </div>
                        <div className="col-md-12">
                            <p>© Copyright 2024, Todos los derechos reservados</p>
                        </div>
                        <div className="col-md-12">
                        <p><a href="https://islandfighters.com/privacy" target="_blank">Política de privacidad</a> - <a href="https://islandfighters.com/terms" target="_blank"> Términos de uso</a></p>
                            {/*<ul>
                                <li>
                                    <a href="terms.html">Términos del servicio</a>
                                </li>
                                <li>
                                    
                                </li>
                            </ul>*/}
                        </div>
                    </div>
                </div>
    </div>
    {/*<div className="back-to-top-btn">
            <div className="back-to-top" style={{ display: 'block'}}>
                <a aria-hidden="true" className="fas fa-angle-up" href="#"></a>
            </div>
    </div>*/}
    
    </>
    );
}


export default FooterComponent;