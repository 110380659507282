import React from "react";
import { Link } from "react-router-dom";

const SwiperItemComponent = (props) => {
    return (<>
        <div className="swiper-slide swiper-bg rounded"  style={{backgroundImage:  'url('+props.imageUrl+')', width: '100%',  margin: 'auto'}}>
                 {/*<div className="top-badge">
                   <div className="video-badge"><img alt="" className="img-fluid" src="images/top-movies.png"/></div>
                </div>*/}
                <div className="content">
                {/*<p className="title" data-swiper-parallax="-30%" data-swiper-parallax-scale=".7">{props.title}</p>*/}
                
                
                <div className="slider-buttons" data-swiper-parallax="-30%" data-swiper-parallax-scale=".7">
                    <span className="caption mb-4 " data-swiper-parallax="-20%">
                    <img className="img-fluid img-title-cover img-center" src={props.imageTitle}></img></span>
                    <Link
                    onClick={() => props.setIdVideo(props.idVideo)}
                    className="btn btn-white hvr-sweep-to-right rounded"
                    to="/movie-single" 
                    tabIndex="0"
                    >
                        <i aria-hidden="true" className="fa fa-play mr-2"></i>{props.ppv=="free" ? "Reproducir" : "Más información"}
                    </Link>
                    {/*<a className="btn hvr-sweep-to-right ml-3 rounded" href="#" tabIndex="0">
                        <i className="fas fa-plus mr-2"></i>Mi lista
            </a>*/}
                    
                </div>
                </div>
            </div></>
    );
}

export default SwiperItemComponent;