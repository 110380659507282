import React from "react";
import { NavbarComponent } from "./navbar";
import { RightMenuComponent } from "./right-menu";

const HeaderComponent = () => {
    return (
        <>
        
        <header className="main-nav clearfix is-sticky">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <NavbarComponent />
                        <RightMenuComponent  key={Date.now()}/>
                    </div>
                </div>
            </header>
        </>
    );
}



export default HeaderComponent;