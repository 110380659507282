import React from "react";
import { Link, useLocation} from "react-router-dom";
import Util from "../../util";

const VideoDetailsComponent = (props) => {
    const [packs, setPacks] = React.useState(null);
    const [inPack, setInPack] = React.useState(null);

    const showPack = () => {
        return (
            <>

                <p></p>
                <h5>{packs.description} por {parseInt(packs.price)/100}€</h5>
                <Link
                className="btn hvr-sweep-to-right rounded"
                
                to="/buy-pack"
                state={{json: props.json, idVideo: props.idVideo, pack: packs}}
                >
                            <i className="fas fa-shopping-cart fa-lg align-center"></i>
                            &nbsp;
                        
                            {packs.name} 
                </Link>
                <p>Los videos comprados en los packs se obtienen de forma permanente</p>
                </>
        );
    }
    const location = useLocation();
    React.useEffect(() => {
        if(props.json.packs!=="") {
             Util.get(Util.API+"packs/?id="+props.json.packs)
            .then((response) => {
                setPacks(response.data);
                }           
            )
            .catch((error) => {
                console.log(error);
            });            
        }
        else {
            setPacks("null");
        }     
        Util.get(Util.API+"packs/?idUser="+localStorage.getItem("id")+"&idVideo="+props.json.id)
            .then((response) => {
                 if(response.data=="OK")
                 {
                     setInPack("true");
                 }
                 else setInPack("false");                
             }           
            )
            .catch((error) => {
                console.log(error);
            });   
      }, [location.key]);

    return (
     (inPack && packs) &&
        <section className="play-details">
        <div className="container-fluid">
            <div className="row">
            
            
            <div className="col-md-9">
                <div className="play-details-content">
                {(packs!=="null" && inPack=="false") ? 
                ""
                :
                ""
            }
                

                <div className="title-block d-flex align-items-center justify-content-between">
                
                    <h2 className="play-title">{props.json.title}</h2>
                </div>
                
                <h3 className="">{props.json.subtitle}</h3>
                <div className="details-info mb-4">
                    {/*<span>
                    <i className="icofont-user mr-2" aria-hidden="true" /> 18+
    </span>*/}
                    <span>
                    <i className="icofont-clock-time mr-2" aria-hidden="true" />{" "}
                    {props.json.duration}
                    </span>
                    <span>
                    <i className="icofont-simple-smile mr-2" aria-hidden="true" />{" "}
                    {props.json.year}
                    </span>
                    <span>
                    <i className="icofont-movie mr-2" aria-hidden="true" /> {props.json.category}
                    </span>
                    <span>
                    <i className="icofont-world mr-2" aria-hidden="true" /> {props.json.languajes}
                    </span>
                </div>
                
                <div className="details-desc">
                    <p>
                    {props.json.description}
                    </p>
                </div>
                
                {/*<div className="movie-persons mb-4">
                    <div className="person-block">
                    <h5 className="title">Director</h5>
                    <p className="name">Christopher Nolan</p>
                    </div>
                    
                    <div className="person-block">
                    <h5 className="title">Cast</h5>
                    <p>
                        Christian Bale, Michael Cain, Gary Oldman, Anne Hathway, Tom
                        Hardy, Marion Cotillard
                    </p>
                    </div>
                    
    </div>*/}
                
                {/*<div className="details-buttons">
                    <div className="row d-flex align-items-center">
                    
                    
                    <div className="col-6 col-xl mb-xl-0 mb-3">
                        <a
                        href="#"
                        className="btn d-block hvr-sweep-to-right rounded"
                        tabIndex={0}
                        >
                        <i className="icofont-plus mr-2" aria-hidden="true" />
                        Mi lista
                        </a>
                    </div>
                    
                    <div className="col-6 col-xl mb-xl-0 mb-3">
                        <a
                        id="trailer"
                        className="btn d-block hvr-sweep-to-right rounded"
                        tabIndex={0}
                        data-toggle="modal"
                        data-target="#trailer-modal"
                        aria-hidden="true"
                        >
                        <i className="icofont-ui-movie mr-2" aria-hidden="true" />
                        Trailer
                        </a>
                        
                        
                        
                    </div>
                    
                    
                    </div>
                    
</div>*/}
                <h2 className="block-title-custom"></h2>
                </div>
                
            </div>
            <div className="col-md-3">
                <div className="row">
                <div className="col-md-12">
                    
                    
                    <div className="thumb-details text-center">
                    <span> 1080p</span>
                    <span>24p</span>
                    <span>
                        <img
                        className="img-fluid"
                        src="images/dts-logo.png"
                        alt=""
                        />
                    </span>
                    <span>7.1</span>
                    </div>
                    
                </div>
                
                </div>
                
            </div>
            </div>
            
        </div>        
        </section>
    );
}

export default VideoDetailsComponent;