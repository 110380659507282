import React from 'react';
import { Route, Routes, BrowserRouter, HashRouter, Navigate, useNavigate} from "react-router-dom";
import { HeaderComponent } from './components/header';
import { MainSliderComponent } from './components/main-slider';
import { MainContentComponent } from './components/main-content';
import { FooterComponent } from './components/footer';
import { WatchMovieComponent}  from './components/watch-movie';
import {MovieSingleComponent} from './components/movie-single';
import { EndFooterComponent } from './components/footer/end-footer';
import {SignUpComponent} from './components/sign-up';
import {LoginComponent} from './components/login';
import {PaymentComponent} from './components/payment';
import { ResetPaswwordComponent } from './components/reset-password';
import TooMuchDevicesComponent from './components/too-much-devices/too-much-devices.component';
import PrivacyPolicyComponent from './components/privacy-policy/privacy-policy.component';
import AccountComponent from './components/account/account.component';
import Util from './components/util';
import PaymentPackComponent from './components/payment-pack/payment-pack.component';
import PaymentSubscriptionComponent from './components/payment-subscription/payment-subscription.component';
import { ActiveSubscriptionComponent } from './components/active-subscription';
import { PaymentLiveComponent } from './components/payment-live';

function App() {
  
  const [ idVideo, setIdVideo ] = React.useState('');
  const [ token, setToken ] = React.useState('');
  const [ login, setLogin ] = React.useState('');
  const [ userValid, setUserValid ] = React.useState('');
  let tokenUser = localStorage.getItem("tokenUser");
  const email = localStorage.getItem("email");
  React.useEffect(()=>{
    //localStorage.removeItem("tokenUser");    
    async function check () {
      await checkUserValid();
      await generateToken(); 
    }
    check();
    setLogin(MainPage);
       
	}, []);

  const CheckDevice = async () => {
      let id = Util.generateUniqueId();
      Util.get(Util.API+"sessions/?session="+id+"&id="+localStorage.getItem("id"))
      .then((response) => {
        if(response.data==='OK')
        {
          setUserValid('true');
        } else {
          setUserValid('false');
        }
        console.log("RD "+response.data+" "+localStorage.getItem("id"));
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("tokenUser");
        tokenUser=null;
      });
  }

  const generateToken = async () => {
    var token_security_key = "";
    Util.get(Util.API+"token")
      .then((response) => {
        const json = response.data;
        token_security_key = json.key;
        setToken(token_security_key);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const checkUserValid = async () => {
      var baseURL = Util.API+"logint/?email="+email+"&token="+tokenUser;
      Util.get(baseURL)
      .then(async (response) => {
        var responseSplit = response.data.split('|astraco|');
        if(responseSplit[0]==tokenUser) {
          localStorage.setItem("id", responseSplit[1]);
          await CheckDevice();
        } else {
          localStorage.removeItem("tokenUser");
          tokenUser=null;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const SetPage = (element) => {
    
    //CheckDevice();
    return (
      <>
      <HeaderComponent />
      <div>
      {element}
      </div>
      <FooterComponent /> 
      </>
    );
  }
  const SetPageNoHeaderFooter = (element) => {
    return (
      <div>
      {element}
      </div>
    );
  }

  const MainPage = () => {
    return (
      <>
      <HashRouter>
      <Routes>
        <Route path='/' element={
          tokenUser==null 
          ? <Navigate to="/login" /> 
          : userValid==='false' ? <TooMuchDevicesComponent /> : SetPage(<MainContentComponent token = {token} setIdVideo={setIdVideo.bind(this)}/>)
          } />
        <Route path='/movies' element={SetPage(<MainSliderComponent token = {token} setIdVideo={setIdVideo.bind(this)}/>)} />       
        <Route path='/about' element={SetPage(<MainContentComponent token = {token} setIdVideo={setIdVideo.bind(this)}/>)} />
        <Route path='/watch-movie' element={<WatchMovieComponent token = {token} idVideo={idVideo}/>} />
        <Route path='/movie-single/*' element={
          tokenUser==null 
          ? <Navigate to="/login" /> 
          : userValid==='false' ? <TooMuchDevicesComponent /> :
          SetPage(<MovieSingleComponent token = {token} setIdVideo={setIdVideo.bind(this)} idVideo={idVideo} key={Date.now()}/>)
          } />
        <Route path='/logout' element={SetPageNoHeaderFooter(<LoginComponent />)} />
        <Route path='/login/*' element={SetPageNoHeaderFooter(<LoginComponent />)} />
        <Route path='/sign-up' element={SetPageNoHeaderFooter(<SignUpComponent />)} />
        <Route path='/reset-password' element={SetPageNoHeaderFooter(<ResetPaswwordComponent />)} />
        <Route path='/buy' component={PaymentComponent} element={SetPage(<PaymentComponent setIdVideo={setIdVideo.bind(this)}  idVideo={idVideo}/>)} />
        <Route path='/buy-pack' component={PaymentPackComponent} element={SetPage(<PaymentPackComponent setIdVideo={setIdVideo.bind(this)}  idVideo={idVideo}/>)} />
        <Route path='/buy-live' component={PaymentLiveComponent} element={SetPage(<PaymentLiveComponent setIdVideo={setIdVideo.bind(this)}  idVideo={idVideo}/>)} />
        <Route path='/buy-subscription' component={PaymentSubscriptionComponent} element={SetPage(<PaymentSubscriptionComponent setIdVideo={setIdVideo.bind(this)}  idVideo={idVideo}/>)} />
        <Route path='/active-subs' component={ActiveSubscriptionComponent} element={SetPage(<ActiveSubscriptionComponent idType="1"/>)} />
        <Route path='/cancel-subs' component={ActiveSubscriptionComponent} element={SetPage(<ActiveSubscriptionComponent idType="0"/>)} />
        <Route path='/privacy' element={SetPage(<PrivacyPolicyComponent />)} />
        <Route path='/account' element={SetPage(<AccountComponent />)} />
      </Routes>        
      </HashRouter>  
      <EndFooterComponent />  
      </>  
    );
  }

  return (    
    /*token &&*/
    <>    
    {<MainPage />}   
    </>
  );
}





export default App;
