import React from "react";
import Axios from "axios";
import { SignUpComponent } from "../sign-up";
import { Route, Routes, BrowserRouter, Navigate, Link } from "react-router-dom";
import {ResetPaswwordComponent} from "../reset-password";
import PrivacyPolicyComponent from "../privacy-policy/privacy-policy.component";
import Util from "../util";

const LoginComponent = () => {
    const [page, setPage] = React.useState('');

    const email = React.createRef();
    const password = React.createRef();
    let isEnd = false;
    React.useEffect(()=>{
        const localToken = localStorage.getItem("tokenUser");
        if(localToken) {
            localStorage.removeItem("tokenUser");            
        }    
        ResetDevice();    
        setPage(LoginPage);
        }, []);
    
    const ResetDevice = async () => {
        let id = Util.generateUniqueId();
        Util.get(Util.API+"sessions/reset/?session="+id)
        .then((response) => {
           // console.log(response.data);            
        })
        .catch((error) => {
            //console.log(error);
        });
    
    }
    const handleSubmit = (event) => {    
        event.preventDefault();
        var baseURL = Util.API+"login/";
        var bodyFormData = new FormData();
        bodyFormData.append('email', email.current.value);
        bodyFormData.append('password', password.current.value);
        Util.post(baseURL, bodyFormData)
        .then((response) => {
          if(response.data!=='') {
              localStorage.setItem('tokenUser', response.data);
              localStorage.setItem('email', email.current.value);
              setPage(EndLoginPage);
              isEnd=true;
          }
          else {
            alert("Email o contraseña incorrecta");
          }
        }).catch((error) => {
           console.log(error);
        });
    }
    const SignUpPage = () => {
        return(
            <SignUpComponent />
        );
    }

    const setSignUpPage = () => {
        setPage(SignUpPage);
    }
    const LoginPage = () => {
        return (
            <div className="main-content">
            
            <section className="login">
                <div className="container">
                    <div className="row text-center intro">
                        <div className="col-12">
                        <img alt="" className="img-fluid" src="images/footer-logo.webp"/>
                            <h2></h2>
                            <p className="text-max-800">- Accede a la plataforma -</p>
                            
                        </div>
                        
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-6">
                            
                            <form id="login-form" onSubmit={handleSubmit} method="post" className="mb-4">
                                <div className="error-container"></div>
                                <div className="form-group">
                                    <label className="control-label col-xs-4">Email</label>
                                    <input id="email" ref={email} type="email" className="form-control" name="email" required="required"/>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-xs-4">Contraseña</label>
                                    <input ref={password} type="password" className="form-control" name="password" required="required"/>
                                </div>
                                <div className="form-group">
                                    {/*<label className="checkbox-inline mb-3"><input type="checkbox" /> Guardar contraseña</label>*/}
                                    <button type="submit" className="btn btn-block hvr-sweep-to-right btn-primary btn-lg">Acceder</button>
                                </div>

                                <Link 
                                to="/reset-password"
                                >
                                    ¿Has perdido tu contraseña?
                                    </Link>

                            </form>
                            
                            
                            <p className="text-center">¿No tienes una cuenta? <Link to="/sign-up" className="text-danger">REGISTRATE</Link></p>
                        </div>
                        
                        <div className="col-lg-6">
                            <img className="img-fluid" src="images\collage-fotos-inicio.webp" alt=""/>
                        </div>
                        
                    </div>
                    
                </div>
                
            </section>
            
        </div>
        );
    }

    const EndLoginPage = () => {
        
        return (
            <div className="main-content">
            <div className="container">
                        <div className="col-12 text-center">
                        <img alt="" className="img-fluid" src="images/footer-logo.webp"/>
                            <h2></h2>
                            <a href="/" id="buttonLogin"><button className="btn btn-block hvr-sweep-to-right btn-primary btn-lg">Continuar</button></a>
                        </div>
                        </div>
                        
                    </div>
        );
    }

    

    return ( 
        <Routes>
          <Route path='/' element={page} />
          <Route path='/login' element={page} />
          <Route path='/sign-up' element={<SignUpPage />} />
          <Route path='/reset-password' element={<ResetPaswwordComponent />} />
        </Routes>        
        
    );
}

export default LoginComponent;