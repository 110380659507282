import React from "react";
import PlayerVideoComponent from "./player-video/player-video.component";
import { useNavigate, useLocation } from "react-router-dom";
import TooMuchDevicesComponent from "../too-much-devices/too-much-devices.component";
import PaymentSubscriptionComponent from "../payment-subscription/payment-subscription.component";
import Util from "../util";



const WatchMovieComponent = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const json = location.state.json;
    const [ userValid, setUserValid ] = React.useState('');
    const [ subscriptionValid, setSubscriptionValid ] = React.useState('');
    
      
  const CheckDevice = async () => {
    let id = Util.generateUniqueId();
    Util.get(Util.API+"sessions/?session="+id+"&id="+localStorage.getItem("id"))
        .then((response) => {
        if(response.data==='OK')
        {
            setUserValid('true');
        } else {
            setUserValid('false');
        }
       // console.log("RD "+response.data);
        })
        .catch((error) => {
        console.log(error);
        });
    }
  const CheckSubscription = async () => {
    let id = Util.generateUniqueId();
    Util.get(Util.API+"subscriptions/?id="+localStorage.getItem("id"))
        .then((response) => {
        if(response.data.includes("- Finaliza "))
        {
            setSubscriptionValid('true');
        } else {
            setSubscriptionValid('false');
        }
       // console.log("RD "+response.data);
        })
        .catch((error) => {
        console.log(error);
        });
    }

    React.useEffect(() => {
        if(props.idVideo<1) {
            navigate(-1);
        }
        async function check () {
            await CheckDevice();   
            await CheckSubscription();         
        }
        check();
               
        
      }, []);
    
      
    
    return(
        userValid==='false'
        ? 
        <TooMuchDevicesComponent />
        : subscriptionValid==='false' ? <PaymentSubscriptionComponent />
        :
    <>
    <div className="video-container">
     <PlayerVideoComponent token={props.token} idVideo={props.idVideo} json={json} />
     {ButtonBack(navigate)}
    </div> 
    
    </>
    );
}

const ButtonBack = (navigate) => {    

    const goBack = () => {
        navigate('/movie-single');
    }
    return(
        <>
        <div 
        role="button"
        className="backDiv"
        style={{ position: 'fixed', top: '0', height: '20%', width: '100%', zIndex: 99998 }}
        > 
         
        </div>
        <img
        className="buttonBack"
        src="images/utils/backArrow.webp"
        role="button"
        onClick={() => {goBack()}}     
        z-index="99999"   
        /> 
        </>
    );
}


export default WatchMovieComponent;