import React from "react";
const SwiperButtonsComponent = (props) => {
    return (
    <>
        <div className={"buttonContainer rounded custom_button_next "+props.percent100}  role="button">  
        <i className="fas fa-chevron-right fa-lg custom-button-center"></i>       
        </div>  
        <div className={"buttonContainer rounded custom_button_prev "+props.percent100}  role="button">   
        <i className="fas fa-chevron-left fa-lg custom-button-center"></i>      
        </div>
    </>
    );
}

export default SwiperButtonsComponent;