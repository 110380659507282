import React from "react";
import {EpisodeItemComponent} from "./episode-item";
import Axios from "axios";
import Util from "../util";

const LatestEpisodiesComponent = (props) => {
    const [renderData, setRenderData] = React.useState(null);
    const [jsonData, setJsonData] = React.useState(null);

    React.useEffect(() => {
        getListVideos();
      }, []);

    const getListVideos = async () => {
        Util.get(props.url+localStorage.getItem("email"))
          .then((response) => {
            const json = response.data;
            setJsonData(json);
            var preJson = json.map((data)=> {
              //console.log("DTA episodes: "+data.id);
              return (
                  <EpisodeItemComponent 
                  setIdVideo={props.setIdVideo.bind(this)}
                  key={Math.floor(Date.now() + Math.random())+data.id}
                  idVideo={data.id}
                  index={data.chapter}
                  imageUrl={data.image2}
                  title={data.title}
                  subtitle={data.subtitle}
                  date={data.year}
                  duration={data.duration}
                  />
              )
          }        
          )
          
          setRenderData(preJson);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    return (
        renderData && renderData.length<1 ? <></> :
        <section className="latest-episodes">
                <div className="container-fluid">
                    <details open="open"><summary>
                    <div className="row">
                        
                        <div className="col-lg-12">
                            <h2 className="block-title">Temporada 1</h2>
                        </div>
                    </div>
                    </summary>
                    <div className="row">
                    {renderData}
                        
                        
                    </div>
                    </details>
                </div>
                
            </section>
    );
}

export default LatestEpisodiesComponent;