import React from 'react';

import { Player, BigPlayButton  } from 'video-react';
import "../../../../node_modules/video-react/dist/video-react.css"; // import css
import sha256 from 'crypto-js/sha256';
import Axios from "axios";
import { Link } from "react-router-dom";

class PlayerVideoClass extends React.Component {
  constructor(props) {    
    super(props);
    this.state = {
      urlToken: '',
      isFrameLoad: ''
    }
    this.ref = React.createRef();
    //this.mediaSource = new MediaSource(this.url);
    this.generateToken = this.generateToken.bind(this);
    //console.log(this.props.json);

  }
  componentDidMount() {
    //this.getVideoBlobUrl();
     //this.updateState(this.generateToken());
   // const frame = document.getElementById("iFrame");
    //var video = frame.getElementsByTagName("video");
      //frame.requestFullscreen();
  } 

  isLoad = false;
  generateToken = () => {
    var token_security_key = this.props.token;
    var video_id = this.props.json.idCDN;
    var expiration = Date.now() + 60 * 1000;
    var url = this.props.json.urlVideo;
    var sha256hex = sha256(token_security_key + video_id + expiration).toString();
    //this.urlToken=url+"?token="+sha256hex+"&expires="+expiration;
    //console.log(url+"?token="+sha256hex+"&expires="+expiration);
    return url+"?token="+sha256hex+"&expires="+expiration;     
  }

  frameLoad = () => {
    if(this.state.isFrameLoad==='')
      this.setState({isFrameLoad: 'true'});
    console.log("frame load");
  }

   //TODO borrar si no la usas
   /*
   getVideoBlobUrl = async () => {
    //const response = await fetch('https://media.w3.org/2010/05/sintel/trailer_hd.mp4');
    const response = await fetch('https://iframe.mediadelivery.net/embed/121430/01506d9f-7a20-44c3-86ae-92147de945d8');
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    this.updateState(url);
  };



  generateUrlTokenUpdate = async () => {
    var token_security_key = "";
    Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    Axios.get("https://islandfighters.com/api-v1/token",{
      headers: 'DNT,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Range',
      allow: 'GET, POST, OPTIONS',
      origin: '*',      
      withCredentials:false
    })
      .then((response) => {
        const json = response.data;
        token_security_key = json.key;
        var video_id = this.props.idVideo;
        var expiration = Date.now() + 60 * 1000;
        var url = "https://iframe.mediadelivery.net/embed/121430/"+video_id;
        var sha256hex = sha256(token_security_key + video_id + expiration).toString();
        this.urlToken=url+"?token="+sha256hex+"&expires="+expiration;
        this.updateState(this.urlToken);
        return url+"?token="+sha256hex+"&expires="+expiration;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  generate
  updateState(newUrl){ 
    // Changing state 
    this.setState({urlToken: 
                 newUrl}) 
  }  
  PlayerRender = () => {
    return(
      <Player
          ref={this.ref}
          toggleFullscreen={true}
          preload='none'
          autoPlay={true}
        fluid={false}
        width={"auto"}
        height={"100%"}
        playsInline
        poster="./../images/parallax/best-series.jpg"
        src={this.state.src}     
          >
          <BigPlayButton position="center" />
          </Player>
    );    
  }
 */
  BunnyRender = () => {
    return(
      <div>         
      <div style={{position: "fixed", paddingTop: "56.25%"}}>     
      <iframe id="iFrame" className="bunny-video" src={this.generateToken()} 
          loading="lazy" style={{border: 'none', position: 'fixed', top: '0', height: '100%', width: '100%', zIndex: "99997"}} 
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowFullScreen={true} onLoad={() => this.frameLoad()}>            
      </iframe>      
    </div>
    </div>
    );
  }
  render() {
    return this.generateToken() &&
    <>
      {this.state.isFrameLoad==='true' ? "" : <div className="text-center align-middle"> <div className="lds-ripple align-middle"><div></div><div></div></div> </div>}
      
      <this.BunnyRender />
      </>
      
    
  }
  
}

const PlayerVideoComponent = PlayerVideoClass;

export default PlayerVideoComponent;
