import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";



const MainSliderItemComponent = (props) => {
    const navigate = useNavigate();
    const refreshPage = () => {
        //navigate(0);
    }
    refreshPage();
    return (
        <div className={"slide slick-bg bg-"+props.count} style={{backgroundImage: 'url('+props.imageUrl+')'}}>
            <div className="container-fluid position-relative h-100">
                <div className="slider-content h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <h3 data-animation-in="fadeInUp" data-delay-in="1"><span className="badge bg-warning text-dark">New</span></h3>
                            <h1 data-animation-in="fadeInUp" data-delay-in="1">{props.title}</h1>
                            <div className="slide-info" data-animation-in="fadeInUp" data-delay-in="1">
                                <span>{props.year}</span> <span className="radius">+18</span> <span>{props.duration}</span>
                            </div>
                            <p data-animation-in="fadeInUp" data-delay-in="1">{props.description}</p>
                            <div className="slider-buttons d-flex align-items-center" data-animation-in="fadeInUp" data-delay-in="1">
                                
                        <Link
                        onClick={() => props.setIdVideo(props.idVideo)}           
                        className="btn hvr-sweep-to-right"
                        to="/watch-movie"
                        tabIndex="0"
                        >
                            <i aria-hidden="true" className="fa fa-play mr-2"></i>Play Now
                        </Link>
                                 <a className="btn hvr-sweep-to-right ml-3" href="#" tabIndex={props.count}><i className="fas fa-plus mr-2"></i>My List</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainSliderItemComponent;