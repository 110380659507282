import React from "react";
import { Link,useNavigate } from "react-router-dom";

const EpisodeItemComponent = (props) => {
    const navigate = useNavigate();
    return (
        <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                            <div className="episode-block">
                                <div className="episode-img position-relative">
                                    <img src={props.imageUrl}className="img-fluid img-zoom" alt=""/>
                                    <div className="episode-number">{props.index}</div>
                                    <div className="episode-play-info">
                                        <div className="episode-play">                                            
                                            <Link
                                            onClick={() => { props.setIdVideo(props.idVideo); }}  
                                            to="/movie-single">
                                            
                                                <i className="far fa-play-circle"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>                                
                                <div className="episode-desc p-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span className="text-white">{props.date}</span>
                                        <span className="text-primary">{props.duration}</span>
                                    </div>
                                    <Link
                                    onClick={() => { props.setIdVideo(props.idVideo); }}  
                                    to="/movie-single">
                                    
                                        <h6 className="episode-name text-white mb-0">{props.title}
                                        </h6>
                                        <h6 className="episode-name text-white mb-0">{props.subtitle}
                                        </h6>
                                    </Link>
                                </div>                                
                            </div>                            
                        </div>
    );
}

export default EpisodeItemComponent;