import React from "react";
import { NavLink } from "react-router-dom";

const DropdownItem = (props) => {
    return (
        <li onClick={event => checkCollapse(event)}>
            <NavLink
              className="dropdown-item"
              to={props.href}
            >
              { props.text }
            </NavLink>
        </li>
    );
}
function checkCollapse(event) {
  //document.getElementById("f-Sidebar").style.width = "100%";
  let isHidden = document.getElementById("buttonCollapse").getAttribute('aria-expanded');
  if(isHidden) {
    document.getElementById("navbarSupportedContent").setAttribute('class','navbar-collapse navbar-responsive-collapse collapse');
  }
}
export default DropdownItem;