import React from "react";
import Axios from "axios";
import Util from "../util";
import { Link } from "react-router-dom";

const AccountComponent = () => {
    const id = localStorage.getItem("id");
    const [json, setJson] = React.useState("");
    const [history, setHistory] = React.useState("");
    const [ subscriptionValid, setSubscriptionValid ] = React.useState('');
    React.useEffect(()=>{
      async function check () {
        await CheckUserAccount(); 
        await CheckSubscription(); 
      }

      check();         
      }, []);
    const CheckUserAccount = async () => {
        Util.get(Util.API+"account/?id="+id)
        .then((response) => {
          if(response.data!=='')
          {
            setJson(response.data);
           
            let jsonRents = response.data.rents;
            console.log(jsonRents);
            setHistory(jsonRents.length <= 0 ? "No has realizado ninguno"
            : jsonRents.map(element => {
                return(<>
                <h5>{element.title}</h5>
                <p>{Util.seconsToDate(element.dateStart*1000)}{element.dateEnd ? " al "+Util.seconsToDate(element.dateEnd*1000) : ""}</p>
                </>);
            }));
            
          } 
        })
        .catch((error) => {
          console.log(error);
        });
    }
    const CheckSubscription = async () => {
        let id = Util.generateUniqueId();
        Util.get(Util.API+"subscriptions/?id="+localStorage.getItem("id"))
            .then((response) => {
            if(response.data)
            {
                setSubscriptionValid(response.data);
            } 
           // console.log("RD "+response.data);
            })
            .catch((error) => {
            console.log(error);
            });
        }

    return (
        json &&
        <div className="main-content">
            <section className="account-settings">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-settings">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link active" data-toggle="pill" href="#pills-profile" role="tab" aria-selected="true">General</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" data-toggle="pill" href="#pills-account" role="tab" aria-selected="false">Configuración</a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div id="pills-profile" className="tab-pane animated fadeInRight show active">
                                        
                                        <form id="profile-settings-form" action="#" method="post">
                                            <div className="error-container"></div>
                                           
                                            
                                            <h5 className="mb-3 mt-3 pb-3 section-border">Datos de usuario</h5>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <div className="border border-gray-500 bg-gray-200 p-3 text-center font-size-sm">
                                                            {json.username}</div> 
                                                         </div>
                                                </div>
                                                
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Email asociado</label>
                                                        <div className="border border-gray-500 bg-gray-200 p-3 text-center font-size-sm">
                                                            {json.email}</div> 
                                                        </div>
                                                </div>
                                                
                                            </div>
                                            <h5 className="mb-3 mt-3 pb-3 section-border">Historial</h5>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group mb-4">
                                                        <label className="d-block">Historial</label>
                                                        <div className="border border-gray-500 bg-gray-200 p-3 text-center font-size-sm">

                                                            {
                                                                history
                                                            }
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            
                                            
                                        </form>
                                        
                                    </div>
                                    
                                    <div id="pills-account" className="tab-pane animated fadeInRight">
                                        
                                        <form id="account-settings-form" action="#" method="post">
                                            <h5 className="mb-3 mt-3 pb-3 section-border">Subscripción</h5>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group mb-3">
                                                        <label>Tipo de subscripción</label>
                                                        <select className="form-control" name="select-plan" aria-label="Default select example" id="select-plan">
                                                            <option value="1">STANDARD Mensual</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label>Estado de la subscripción</label>
                                                        <div className="border border-gray-500 bg-gray-200 p-3 text-center font-size-sm">
                                                            
                                                                <>{subscriptionValid}</>
                                                            </div> 
                                                    </div>
                                                    { 
                                                    <div className="form-group mb-3">
                                                        {subscriptionValid.includes("Activa -") 
                                                        ? <Link
                                                        to="/cancel-subs"
                                                        ><button className="btn btn-info hvr-sweep-to-right" type="button">Cancelar subscripción</button>
                                                        </Link>
                                                    : 
                                                    subscriptionValid.includes(" - Finalizado") 
                                                    ?
                                                    <Link
                                                    to="/buy-subscription"
                                                    ><button className="btn btn-info hvr-sweep-to-right" type="button">Subscribirse</button>
                                                    </Link>
                                                    :
                                                    subscriptionValid.includes("Inactiva -") 
                                                    ?
                                                    <Link
                                                    to="/active-subs"
                                                    ><button className="btn btn-info hvr-sweep-to-right" type="button">Activar subscripción</button>
                                                    </Link>
                                                    :
                                                    <Link
                                                    to="/buy-subscription"
                                                    ><button className="btn btn-info hvr-sweep-to-right" type="button">Subscribirse</button>
                                                    </Link>
                                                    }
                                                    </div>
                                                    
                                                    }
                                                    
                                                </div>
                                                
                                            </div>
                                            {
                                                 /*
                                            <h5 className="mb-3 mt-3 pb-3 section-border">Método de pago</h5>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group mb-4">
                                                        <label className="d-block mb-0">Método de pago</label>
                                                        <div className="small text-muted mb-3">No has añadido ningún método de pago</div>
                                                        <button onClick={() =>Util.noImplementedYet() } className="btn btn-info hvr-sweep-to-right" type="button">Añadir método de pago</button>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            */
                                            }
                                          
                                            
                                            
                                        </form>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    
                </div>
                
            </section>
            
        </div>
    );
}
export default AccountComponent;