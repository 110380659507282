import React from "react";
import { useLocation, Link } from "react-router-dom";
import Util from "../util";

const PaymentSubscriptionComponent = (props) => {
    const location = useLocation();
    //const json = location.state.json;
    //const pack = location.state.pack;
    const [buyComplete, setBuyComplete] = React.useState('');
    const [priceSubscription, setPriceSubscription] = React.useState('');

    const getStatusRent = () => {
      Util.get(Util.API+"status-rent/?idSubscription="+"1"+"&email="+localStorage.getItem('email'))
        .then(async (response) => {
            if(response.data==1) {
              setBuyComplete('true');
              return;
            }
            if(response.data==0) {
              setBuyComplete('false');
              return;
            }
            if(response.data==-1) {
              setBuyComplete('');
            }
            await new Promise(r => setTimeout(r, 2000));
            getStatusRent();
            return;
          })
          .catch((error) => {
            console.log(error);
          });
    }
    const getPriceSubscription = () => {
      Util.get(Util.API+"subscriptions/price.php?id="+localStorage.getItem('id'))
        .then(async (response) => {
            if(response.data) {
              setPriceSubscription(response.data/100);
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    React.useEffect(() => {      
      window.scrollTo(0, 0);
          async function check() {
            await new Promise(r => setTimeout(r, 2000));
            getStatusRent();
          }
          check();
          getPriceSubscription();

      }, [location.key]);


    
  return (
    <div>
      
      <div className="play-thumb mb-4 text-center rounded">
            <br></br>
            <br></br>
            
            <h5 className="text-danger">Subscripción mensual</h5>
            <Link
            to="/movie-single"
            >
            <img  className="img-fluid img-thumb rounded"
              src="http://localhost:3000/images/footer-logo.webp"
              alt=""
            />
            <br></br>
            <img  className="img-fluid img-thumb rounded"
              src="https://islandfighters.com/tv/images/collage-fotos-inicio.webp"
              alt=""
            />
            </Link>
            <br></br>
            <br></br>
            <h5 className="text-concept"><span className="text-danger"><small>Subscribirse por </small></span>{priceSubscription}€/Mes</h5>
    </div>
      {buyComplete==='' ? <iframe id="iFrame" className="" 
      src={"https://islandfighters.com/redsys/getPetSubscription.php?idSuscription="+"1"+"&email="+localStorage.getItem('email') }
      style={{border: 'none',  height: '200px', width: '100%'}}
          loading="lazy"
          >            
      </iframe>



      : buyComplete==='true' 
        ? <div>
            <h5 className="text-center m-5">Compra completada</h5>
            <Link
            to="/movie-single"
            >
            <button type="submit" href="/" class="btn btn-block hvr-sweep-to-right  btn-danger btn-lg">Volver</button>
            </Link>
            </div>
        : <div>
            <h5 className="text-center m-5">No se ha podido completar la operación</h5>
            <Link
            to="/movie-single"
            >
            <button type="submit" href="/" class="btn btn-block hvr-sweep-to-right  btn-danger btn-lg">Volver</button>
            </Link>
        
        </div>
      }
    </div>
    
  );
};

export default PaymentSubscriptionComponent;